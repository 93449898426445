import React, {useState, useEffect} from "react"
import { graphql, Link } from "gatsby"
import Img from 'gatsby-image'
import { createContent } from "../common/Theme"
import Layout from "../components/layout"
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  makeStyles,
  useMediaQuery
} from '@material-ui/core';
import ContactUs from "../components/contactUs"
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { theme } from '../common/Theme';
import AnimateHeight from 'react-animate-height';

import '../assets/styles/templates/caseTemplate.scss';

const useStyles = makeStyles({
  gridItem: {
    paddingBottom: '0 !important',
    width: '100%'
  },
  contentContainer: {
    paddingTop: 0
  },
  // dropdownCard: {
  //   paddingTop: 0,
  //   paddingBottom: 0,
  // }
})

const CaseTemplate = ({ data }) => {
  const content = createContent();
  const classes = useStyles();
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const [isDropdownOpen, setIsDropdownOpen] = useState(isMd);

  useEffect(() => {
    setIsDropdownOpen(isMd);
  }, [isMd]);

  const handleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  }

  return (
    <Layout>
      <section id='case-section'>
        <Container classes={{root: content.content}}>

        <Link to='/projects' className='project-back-link'>
          <ArrowRightAltIcon />
          <span>All projects</span>
        </Link>

        <Typography variant='h1' className='project-title'>{frontmatter.title}</Typography>

        <Grid container spacing={6}>
          {/*<Grid item md={12} classes={{root: classes.gridItem}}>*/}
          {/*</Grid>*/}
          {/*<Grid item md={6} />*/}
          <Grid item sm={12} md={6} classes={{root: classes.gridItem}}>
            <Typography className='project-overview'>{frontmatter.overview}</Typography>
          </Grid>
          <Grid item sm={12} md={6} classes={{root: classes.gridItem}} className='project-details'>
            <Card elevation={0}>
              {
                !isMd &&
                <CardContent div className='project-details-handler' onClick={handleDropdown}>
                  <Typography variant='h6'>Project Details</Typography>
                  { isDropdownOpen ? <RemoveIcon /> : <AddIcon /> }
                </CardContent>
              }
              <AnimateHeight
                duration={isMd ? 0 : 500}
                height={isDropdownOpen ? 'auto' :  0}
              >
                <CardContent className={`project-details-dropdown${isMd ? ' has-padding' : ''}`}>
                  <Grid container spacing={2}>
                    <Grid item md={6} sm={6} xs={6}>
                      {
                        frontmatter.client &&
                        <div className='project-client'>
                          <Typography variant='h6'>Client</Typography>
                          <Typography variant='body2'>{frontmatter.client}</Typography>
                        </div>
                      }
                      {
                        frontmatter.timeframe &&
                        <div className='project-timeframe'>
                          <Typography variant='h6'>Timeframe</Typography>
                          <Typography variant='body2'>{frontmatter.timeframe}</Typography>
                        </div>
                      }
                    </Grid>
                    <Grid item md={6} sm={6} xs={6}>
                      <div className='project-services'>
                        <Typography variant='h6'>Our services</Typography>
                        <Typography variant='body2'>
                          <ul>
                            {
                              frontmatter.services.map(service => {
                                return (
                                  <li>{service}</li>
                                )
                              })
                            }
                          </ul>
                        </Typography>
                      </div>
                      {
                        frontmatter.technologies &&
                        <div className='project-technologies'>
                          <Typography variant='h6'>Technology</Typography>
                          <Typography variant='body2'>
                            <ul>
                              {
                                frontmatter.technologies.map(technology => {
                                  return (
                                    <li>{technology}</li>
                                  )
                                })
                              }
                            </ul>
                          </Typography>
                        </div>
                      }
                    </Grid>
                  </Grid>


                    {/*{*/}
                    {/*  frontmatter.link &&*/}
                    {/*  <Grid item md={12}>*/}
                    {/*    <div className='project-website'>*/}
                    {/*      /!*<Typography variant='h6'>Website</Typography>*!/*/}
                    {/*      <a href={frontmatter.link} target='_blank'>*/}
                    {/*        <Button text='Visit site' />*/}
                    {/*      </a>*/}
                    {/*    </div>*/}
                    {/*  </Grid>*/}
                    {/*}*/}
                </CardContent>
              </AnimateHeight>
            </Card>
          </Grid>
        </Grid>

        {
          frontmatter.projectImage &&
          <div className='project-image'>
            <Img fluid={frontmatter.projectImage.childImageSharp.fluid} />
          </div>
        }


        </Container>
      </section>

      <section>
        <Container classes={{root: `${content.content} ${classes.contentContainer}` }}>
          <div


            className="project-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />

          {/*{*/}
          {/*  frontmatter.link &&*/}
          {/*    <div className='project-external-link'>*/}
          {/*      <a href={frontmatter.link} target='_blank'>*/}
          {/*        <Button text='Visit site' />*/}
          {/*      </a>*/}
          {/*    </div>*/}
          {/*}*/}
        </Container>
      </section>


      {/*<section className='project-image'>*/}
      {/*  <Container classes={{root: classes.content}}>*/}
      {/*    <Img fluid={frontmatter.projectImage.childImageSharp.fluid} />*/}
      {/*  </Container>*/}
      {/*</section>*/}

      <ContactUs />
    </Layout>
  )
}

export default CaseTemplate;

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        overview
        client
        timeframe
        services
        technologies
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        projectImage {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        link
      }
    }
  }
`
